import React from "react";
import { Link } from "gatsby";

import { KleinboyArticle, KleinboyTag } from "../types";

import { TagBadgeGroup } from "./TagBadgeGroup";
import { displayDate } from "../utils/date";

type Props = {
  article: KleinboyArticle;
  tagMap?: { [k: string]: KleinboyTag };
};

export const ArticleListItem = ({
  article: { path, publishedTime, title, description, tags: tagKeys },
  tagMap,
}: Props): JSX.Element => {
  const tags = tagMap != null ? tagKeys.map((t) => tagMap[t]).filter((x) => x != null) : [];
  return (
    <li
      style={{
        margin: "3rem 0 1rem 0",
        border: "1px solid #c7243a",
        borderRadius: "6px",
        padding: "2.5rem 2rem 1.5rem 2rem",
      }}
    >
      <div>
        <strong style={{ fontSize: "2.5rem", fontWeight: 600 }}>
          <Link to={`/${path}/`}>{title}</Link>
        </strong>
      </div>
      <div
        style={{
          fontSize: "1.4rem",
          margin: "1rem 0",
        }}
      >
        {description}
      </div>
      <div style={{ textAlign: "right" }}>
        <TagBadgeGroup tags={tags} />
        {tags.length !== 0 ? " " : undefined}
        {publishedTime != null ? <span style={{ color: "gray" }}>{displayDate(publishedTime)}</span> : undefined}
      </div>
    </li>
  );
};
