import React from "react";
import { PageProps, Link, graphql } from "gatsby";

import { Layout } from "../components/globals/Layout";
import { SEO } from "../components/globals/SEO";
import { KleinboyArticle, KleinboyTag } from "../types";
import { TagBadgeGroup } from "../components/TagBadgeGroup";
import { displayDate } from "../utils/date";
import { fromEntries } from "../utils/map";
import { ArticleList } from "../components/ArticleList";

export const query = graphql`
  query($tagKey: String!) {
    kleinboyTag(key: { eq: $tagKey }) {
      key
      title
      article {
        file {
          html
        }
      }
    }
    allKleinboyArticle(filter: { tags: { eq: $tagKey } }) {
      nodes {
        path
        title
        description
        tags
        publishedTime
      }
    }
    allKleinboyTag {
      nodes {
        key
        title
      }
    }
  }
`;

type DataProps = {
  kleinboyTag: KleinboyTag & { article?: KleinboyArticle & { file: { html: string } } };
  allKleinboyArticle: { nodes: KleinboyArticle[] };
  allKleinboyTag: { nodes: KleinboyTag[] };
};

type PageContext = {
  path: string;
  previous?: KleinboyArticle;
  next?: KleinboyArticle;
};

const TagDetail: React.FC<PageProps<DataProps, PageContext>> = ({ data, pageContext: { previous, next } }) => {
  const { title, article } = data.kleinboyTag;
  const tagMap = fromEntries(data.allKleinboyTag.nodes.map((n) => [n.key, n]));
  return (
    <Layout>
      <SEO title={title} />
      <article>
        <section id="post-section">
          {article != null ? <div dangerouslySetInnerHTML={{ __html: article.file.html }}></div> : <h1>{title}</h1>}
          <h2>記事一覧</h2>
          <ArticleList articles={data.allKleinboyArticle.nodes} tagMap={tagMap} />
        </section>
      </article>
    </Layout>
  );
};

export default TagDetail;
