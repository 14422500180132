import React from "react";

import { KleinboyArticle, KleinboyTag } from "../types";
import { ArticleListItem } from "./ArticleListItem";

type Props = {
  articles: KleinboyArticle[];
  tagMap?: { [k: string]: KleinboyTag };
};

export const ArticleList: React.FC<Props> = ({ articles, tagMap }: Props) => (
  <ul style={{ listStyle: "none" }}>
    {articles.map((article) => (
      <ArticleListItem key={article.path} article={article} tagMap={tagMap} />
    ))}
  </ul>
);
